import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../component/Layout'
import RenderIf from '../../component/RenderIf'
import DetailPageHeader from '../../component/DetailPageHeader'
import ContentRender from '../../component/ContentRender'
import BadgeList from '../../component/BadgeList'
import SEO from '../../component/SEO'
import { PageMenus } from '../../types/PageMenus'
import {
  Menu_Link_Content__Gatsby_Site_Header,
  Menu_Link_Content__Gatsby_Site_Footer,
  Menu_Link_Content__Gatsby_Site_Socials,
  Node__School,
  NodeSchoolFieldsFragment,
} from '../../types/generated'
import { LanguageCode } from '../../types/LanguageCode'
import { getSeoImage } from '../../utils'
import Button from '../../component/Button'
// @ts-ignore
import { translate } from '../../translate'

interface SchoolData {
  nodeSchool: Node__School
  englishPage: Node__School
  spanishPage: Node__School
  somaliPage: Node__School
  vietnamesePage: Node__School
  filipinoPage: Node__School
  footerMenu: { nodes: Menu_Link_Content__Gatsby_Site_Footer[] }
  headerMenu: { nodes: Menu_Link_Content__Gatsby_Site_Header[] }
  socialMenu: { nodes: Menu_Link_Content__Gatsby_Site_Socials[] }
}

interface Props {
  data: SchoolData
}

export const query = graphql`
  query SchoolTemplateQuery($id: String!, $nid: Int!, $langcode: String!) {
    ...HeaderMenu
    ...FooterMenu
    ...SocialMenu

    nodeSchool: nodeSchool(id: { eq: $id }) {
      ...NodeSchoolFields
    }

    englishPage: nodeSchool(drupal_internal__nid: { eq: $nid }, langcode: { eq: "en" }) {
      path {
        alias
      }
    }

    spanishPage: nodeSchool(drupal_internal__nid: { eq: $nid }, langcode: { eq: "es" }) {
      path {
        alias
      }
    }

    somaliPage: nodeSchool(drupal_internal__nid: { eq: $nid }, langcode: { eq: "so" }) {
      path {
        alias
      }
    }

    vietnamesePage: nodeSchool(drupal_internal__nid: { eq: $nid }, langcode: { eq: "vi" }) {
      path {
        alias
      }
    }

    filipinoPage: nodeSchool(drupal_internal__nid: { eq: $nid }, langcode: { eq: "fil" }) {
      path {
        alias
      }
    }
  }
`

const School = ({ data }: Props) => {
  const school = data.nodeSchool
  const {
    footerMenu,
    headerMenu,
    socialMenu,
    englishPage,
    spanishPage,
    somaliPage,
    vietnamesePage,
    filipinoPage,
  } = data

  const t = (text: string) => translate(school?.langcode, text)

  const translationPaths = {
    en: englishPage?.path?.alias,
    es: spanishPage?.path?.alias,
    so: somaliPage?.path?.alias,
    vi: vietnamesePage?.path?.alias,
    fil: filipinoPage?.path?.alias,
  }

  const specialties = school?.relationships?.field_specialties
  const careerPathways = school?.relationships?.field_career_pathways
  const awards = school?.relationships?.field_awards

  const menu: PageMenus = {
    header: headerMenu.nodes,
    footer: footerMenu.nodes,
    social: socialMenu.nodes,
  }

  return (
    <Layout
      menu={menu}
      langcode={school?.langcode as LanguageCode}
      translationPaths={translationPaths}
    >
      <SEO
        title={school?.title}
        description={school?.bodySummary}
        image={getSeoImage(school?.relationships?.field_image)}
        url={school?.path?.alias}
      />
      <div className="pb-8">
        <DetailPageHeader header={school as NodeSchoolFieldsFragment} />
      </div>
      <div className="mt-4 flex mr-5 flex-wrap my-8">
        <BadgeList
          items={specialties}
          backgroundColor="bg-sdusd-purple"
          textColor="text-sdusd-purple"
          padding="p-2"
        />
        <BadgeList
          items={careerPathways}
          backgroundColor="bg-sdusd-blue"
          textColor="text-sdusd-blue"
          padding="p-2"
        />
        <BadgeList items={awards} />
      </div>
      <div className="my-8">
        <h2 className="text-2xl mt-4">{t('Features at a glance')}</h2>
        <div className="flex flex-wrap mt-5 custom-space">
          <RenderIf condition={Boolean(school.field_foreign_language_instructi)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('Foreign language instruction')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_career_technical_education)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Career technical education courses')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_organic_garden_on_campus)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('An organic garden on campus')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_special_olympics_program)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('Special Olympics program')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_avid_program)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('AVID college preparedness school')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_ecology_environment)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Ecology and environment learning programs')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_sports_and_athletics_clubs)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('School-based sports and athletics clubs')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_preschool_program)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('A quality preschool school')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_parent_involvement_program)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Parent involvement programs, including a parent center')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_military_family_life)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Support from a Military Family Life counselor')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_military_support)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Additional support and programs for military families')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_lgbtqia_support)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Supports for LGBTQIA students, including a GSA club')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_wellness_programs)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Wellness programs and onsite wellness coordinator')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_anti_bullying_leadership)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Safe and inclusive anti-bullying leadership programs')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_community_business_partner)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Community and business partnerships')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_internship_steam)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Internships with local companies in STEAM fields')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_dual_college_enrollment)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Dual college enrollment enabling students to earn college credits')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_scholarship_athletics)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('Scholarship-level athletics')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_after_school_enrichment)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('After-school learning enrichment opportunities')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_jrotc_program)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Military JROTC school offering leadership development')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_internship_apprenticeship)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Opportunities for internships and apprenticeships')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_before_after_school_care)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('Before and after school care')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_addtl_vis_and_perf_arts)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Additional Visual and Performing Arts clubs, partnerships, and opportunities')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_gifted_and_talented)}>
            <p className="p-2 rounded-xl shadow text-sm ">{t('Gifted and Talented Education')}</p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_project_based_learning)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('Integrated project-based learning')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_steam_makerspace)}>
            <p className="p-2 rounded-xl shadow text-sm ">
              {t('State-of-the-art STEAM Makerspace')}
            </p>
          </RenderIf>
          <RenderIf condition={Boolean(school.field_visual_and_performing_arts)}>
            {school?.field_visual_and_performing_arts?.map((art) => (
              <p className="p-2 rounded-xl shadow text-sm ">{t(`${art}`)}</p>
            ))}
          </RenderIf>
        </div>
      </div>
      <div className="max-w-screen-md mx-auto my-8">
        <ContentRender>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: school?.body?.value ?? '' }} />
        </ContentRender>
        <section>
          <div className="my-8">
            <h2 className="text-2xl">{t('Contact us')}</h2>
            <div className="mt-4 md:mt-8 flex flex-col md:flex-row">
              <div>
                <h3 className="text-sdusd-yellow-100">{t('Address')}</h3>
                <div className="mt-3">{school.title}</div>
                <div>{school?.field_address?.address_line1}</div>
                <div>{school?.field_address?.address_line2}</div>
                <div>
                  <span>{school?.field_address?.locality},</span>
                  <span className="ml-1">{school?.field_address?.administrative_area}</span>
                  <span className="ml-1">{school?.field_address?.postal_code}</span>
                </div>
              </div>
              <RenderIf condition={!!school?.field_website?.title || !!school?.field_website?.uri}>
                <div className="mt-4 md:mt-0 md:ml-36">
                  <h3 className="text-sdusd-yellow-100">{t('Get in touch')}</h3>
                  <div className="mt-3">
                    <a
                      href={`${school?.field_website?.uri}`}
                      className="hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {school?.field_website?.title || school?.field_website?.uri}
                    </a>
                  </div>
                </div>
              </RenderIf>
            </div>
          </div>
          <div className="flex justify-center">
            <a
              href="https://www.sandiegounified.org/departments/neighborhood_schools_and_enrollment_options/choice_enrollment_guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>{t('Enrollment Application')}</Button>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default School
