import React, { ReactNode, MouseEventHandler } from 'react'

interface Props {
  children: ReactNode
  className?: string
  textColor?: string
  paddingY?: string
  paddingX?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  focusBackgroundColor?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | null
}

const Button = ({
  children,
  className = '',
  textColor = 'text-white',
  paddingY = 'py-2',
  paddingX = 'px-4',
  backgroundColor = 'bg-sdusd-green-200',
  hoverBackgroundColor = 'bg-sdusd-green-300',
  focusBackgroundColor = 'bg-sdusd-green-300',
  onClick = null,
}: Props) => {
  if (onClick === null) {
    return (
      <div
        className={`${className} ${textColor} ${paddingY} ${paddingX} ${backgroundColor} hover:${hoverBackgroundColor} focus:outline-none focus:${focusBackgroundColor} inline-flex items-center  justify-center border border-transparent rounded-md shadow-sm text-base font-medium`}
      >
        {children}
      </div>
    )
  }

  return (
    <button
      onClick={onClick}
      className={`${className} ${textColor} ${paddingY} ${paddingX} ${backgroundColor} hover:${hoverBackgroundColor} focus:outline-none focus:${focusBackgroundColor}
    inline-flex items-center  justify-center border border-transparent rounded-md shadow-sm text-base font-medium`}
      type="button"
    >
      {children}
    </button>
  )
}

export default Button
