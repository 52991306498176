import React from 'react'
import { Link } from 'gatsby'
import { getPublicImageTransformationUrl } from '../../utils'

const BadgeList = ({ items, backgroundColor = '', textColor = '', padding = '' }: any) =>
  items?.map((badge: any) => {
    const width = 'w-8'
    const height = 'h-8'
    const icon =
      badge?.relationships?.field_icon_image?.relationships?.field_media_image?.uri?.value

    const iconSrc = getPublicImageTransformationUrl(icon, [
      {
        operation: 'scale_and_crop',
        parameters: {
          width: 128,
          height: 128,
        },
      },
    ])

    return (
      <Link
        key={badge.id}
        className="flex items-center justify-left mr-6 mb-6 hover:underline focus:underline"
        to={badge.path.alias}
      >
        <div className={`flex-shrink-0 ${backgroundColor} rounded-xl ${padding}`}>
          <img
            src={iconSrc}
            className={`${height} ${width}`}
            width={32}
            height={32}
            alt={badge?.relationships?.field_icon_image?.thumbnail?.alt}
            loading="lazy"
          />
        </div>
        <div className={`ml-4 ${textColor}`}>{badge.title}</div>
      </Link>
    )
  })

export default BadgeList
